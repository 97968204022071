import 'babel-polyfill';

// ////////////////////////////////////////////////////////////
// bugsnag
import bugsnag from 'bugsnag-js';

if (CONFIG.bugsnag) {
    window.bugsnagClient = bugsnag({
        apiKey: CONFIG.bugsnag,
        collectUserIp: false,
        releaseStage: CONFIG.env,
        notifyReleaseStages: ['production', 'prod', 'stage'],
    });
}

if (process.env.NODE_ENV === 'production') {
    let loadManifest = async () => {
        // manifest.json is not created in non-production mode
        try {
            let response = await $.getJSON('/assets/manifest.json');

            CONFIG.manifest = response || {};
        } catch (reason) {
            // failed loading
            if (window.bugsnagClient) {
                window.bugsnagClient.notify(reason);
            }
        }
    };

    loadManifest();
} else {
    CONFIG.manifest = {};
}

// ////////////////////////////////////////////////////////////
// import version from './lib/version';
import './_includes/foundation';

let ignoreOnBeforeUnload = false,
    $primaryNav = $('.primary-nav');

$primaryNav.show();

// ////////////////////////////////////////////////////////////
// init and config GA
require(['./lib/analytics/cf7']);

// ////////////////////////////////////////////////////////////
// init foundation
const body = document.body,
    $menuButton = $('.menu-toggle'),
    $offCanvasWrapper = $('.off-canvas-wrapper'),
    offCanvasTransition = $('.off-canvas').data('transition'),
    resizableIFrames = document.querySelectorAll('iframe[data-resizable]');

// show focus style only after a tab keystroke
function handleFirstTab(event) {
    if (event.keyCode === 9) {
        body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);

// fade out on page leave
$('a[href^="mailto"], a[href^="tel"], a[download], .download, a[href^="javascript:"]').on('click', () => {
    ignoreOnBeforeUnload = true;
});

window.addEventListener('beforeunload', () => {
    if (!ignoreOnBeforeUnload) {
        body.classList.add('body--fade-out');
    }

    ignoreOnBeforeUnload = false;
});

$('a[disabled]').on('click', event => {
    event.preventDefault();
    event.stopImmediatePropagation();
});

$(document)
    .foundation()
    .on('opened.zf.offcanvas', () => {
        $menuButton.addClass('is-active');
        $offCanvasWrapper.addClass('is-off-canvas-open');
    })
    .on('closed.zf.offcanvas', () => {
        $menuButton.removeClass('is-active');
        $offCanvasWrapper.removeClass('is-off-canvas-open');
    });

if (offCanvasTransition && offCanvasTransition === 'push') {
    // this is only required, if the header is supposed to be pushed too
    body.classList.add('is-off-canvas-push');
}

// you can listen to breakpoint changes like so:
// $(window).on('changed.zf.mediaquery', (event, current, old) => {
//    console.log(`Breakpoint changed from "${old}" to "${current}"`);
// });

// ////////////////////////////////////////////////////////////
// splashscreen
import './lib/splashScreen';

// ////////////////////////////////////////////////////////////
// form helpers
import './lib/form';

// ////////////////////////////////////////////////////////////
// swiper
import './lib/swiper';

// ////////////////////////////////////////////////////////////
// map
// import './lib/map';

// ////////////////////////////////////////////////////////////
// init all webcams
import './lib/webcam';

// ////////////////////////////////////////////////////////////
// init apartment search
import './lib/apartmentSearch';

// ////////////////////////////////////////////////////////////
// browser alerts (async)
require(['./lib/alerts/old-browser'/*, './lib/alerts/cookies-info'*/], (oldBrowserAlert/*, cookieInfoAlert*/) => {
    oldBrowserAlert.default();

    /*
        is managed by plugins
        cookieInfoAlert.default();
    */
});

// ////////////////////////////////////////////////////////////
// init web share api polyfill. Should be loaded as late as possible
import { shareApi } from './lib/share';
shareApi();

import 'iframe-resizer/js/iframeResizer';
resizableIFrames.forEach(iFrame => {
    window.iFrameResize({
        checkOrigin: true,
        bodyBackground: 'transparent',
    }, iFrame);
});

import jQuery from 'jquery';
import 'imagesloaded/imagesloaded.pkgd';

jQuery($ => {
    if ($('body').hasClass('with-splash-screen')) {
        require.ensure([], () => {
            let $splashScreen = $('.splash-screen');

            $splashScreen.imagesLoaded()
                .then(() => {
                    setTimeout(() => {
                        $('.logo').show();
                        setTimeout(() => {
                            $splashScreen.fadeOut();
                        }, 1500);
                    }, 250);
                });
        }, 'splashScreen');
    }
});

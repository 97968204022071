import jQuery from 'jquery';

jQuery($ => {
    let $webcams = $('.webcam');

    if ($webcams.length) {
        require.ensure([], require => {
            let Webcam = require('./class/Webcam').default;

            const webcamInit = function () {
                $('.webcam').each(function (index, webcam) {
                    let $webcam = $(webcam);

                    new Webcam($webcam, $webcam.data('source'));
                });
            };

            window.webcam = webcamInit;
            webcamInit();
        }, 'webcams');
    }
});

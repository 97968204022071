import $ from 'jquery';

var $swiper = $('.swiper-container');

// only load our swiper if we have one on the current page
if ($swiper.length) {
    require.ensure([], () => {
        require(['swiper'], ({ default: Swiper }) => {
            new Swiper($swiper, {
                loop: true,
                spaceBetween: 10,
                navigation: {
                    nextEl: $('.swiper-container').closest('.row').nextAll('.swiper-button-next')[0],
                    prevEl: $('.swiper-container').closest('.row').nextAll('.swiper-button-prev')[0],
                },
                pagination: {
                    el: $('.swiper-container').closest('.row').nextAll('.swiper-pagination')[0],
                    clickable: true,
                    renderBullet(index, className) {
                        return `<button class="${className}"></button>`; // do not add type=button as the reset-stylesheet adds too specific styles otherwise
                    },
                },
                autoplay: {
                    delay: 4500,
                },
                on: {
                    init() {
                        window.initializedSwiper = this;

                        if (window.whenSwiperReady && window.whenSwiperReady.length) {
                            window.whenSwiperReady.forEach(fn => fn(this, $swiper));
                        }

                        // add a tooltip to each copyright-button
                        // this needs to be done here as Foundation does not initialize duplicated (due to loop-setting)
                        // buttons
                        $swiper.find('[data-copyright]').each((i, copyrightButton) => {
                            new Foundation.Tooltip($(copyrightButton), {
                                alignment: 'center',
                                position: 'bottom',
                            });
                        });
                    },
                },
            });
        });
    }, 'swiper');
} else {
    // add tooltip when there is only one slide
    $('.header-slides').find('[data-copyright]').each((i, copyrightButton) => {
        new Foundation.Tooltip($(copyrightButton), {
            alignment: 'center',
            position: 'bottom',
        });
    });
}

import $ from 'jquery';

// only load our form helpers if we have a form on the current page
if ($('form').length) {
    require.ensure([], () => {
        let selectAll,
            fileInput;

        // enable an (de)select all radiobutton and checkboxes possibility
        selectAll = require('@spinnwerk/form-helper/select-all').default;
        selectAll();

        // style advanced file inputs
        fileInput = require('@spinnwerk/form-helper/file-input').default;
        fileInput({
            multibleFilesLabel: ':anz Dateien ausgewählt',
        });

        // include form validation via parsley (with German language)
        require(['parsleyjs/src/i18n/de'], () => {
            // add parsley only to those forms, that should be validated
            $('form').not('[novalidate]').parsley({
                errorClass: 'has-error',
                successClass: 'is-valid',
                errorsWrapper: '<ul class="form-errors"></ul>',
            });
        });
    }, 'form-helpers');
}

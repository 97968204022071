import jQuery from 'jquery';

jQuery($ => {
    function initApartmentSearch() {
        if (CONFIG.manifest) {
            import(

                /* webpackChunkName: "apartment-search" */
                /* webpackMode: "lazy" */
                './../components'
            );
        } else {
            setTimeout(initApartmentSearch, 100);
        }
    }

    if ($('#apartmentSearch').length) {
        setTimeout(initApartmentSearch, 50);
    }
});
